import * as React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Leftcolumn from './components/Leftcolumn';
import Rightcolumn from './components/Rightcolumn';
// import { createTheme } from '@mui/material';

// isSmallScreen ? "column" : "row"
// const theme = createTheme();
const App = () => {
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box sx={{ height: '100vh', overflow: 'hidden', display: 'flex' }}>
        <Grid
          container
          sx={{
            height: '100%',
            width: '100%',
            overflow: {
              xs: 'auto',
              sm: 'auto',
              md: 'auto',
              lg: 'hidden',
              xl: 'hidden',
            },
          }}
        >
          {/* Left Column */}
          <Grid
            item
            lg={4} // 1/3 of the width
            md={12}
            sm={12}
            xs={12}
            sx={{
              backgroundColor: '#ffffff', // Background color for left column
              position: { xs: 'relative', md: 'fixed' },
              width: { xs: '100%', md: '33.33%' }, // Width for larger screens
              height: { xs: 'auto', md: '100%' },
              overflow: {
                xs: 'hiddn',
                sm: 'hidden',
                md: 'hidden',
                lg: 'hidden',
                xl: 'hidden',
              },
              zIndex: { md: 1 },
            }}
          >
            <Leftcolumn />
          </Grid>

          {/* Right Column */}
          <Grid
            item
            lg={8} // 2/3 of the width
            md={12}
            sm={12}
            xs={12}
            sx={{
              marginLeft: { md: '33.33%' },
              backgroundColor: '##F2F2F2',
              height: { xs: 'auto', md: '100%' },
              overflow: {
                xs: 'hidden',
                sm: 'hidden',
                md: 'hidden',
                lg: 'scroll',
                xl: 'scroll',
              },
              position: { xs: 'relative', md: 'absolute' },
            }}
          >
            <Rightcolumn />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default App;
