import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LaunchIcon from '@mui/icons-material/Launch';
import GitHubIcon from '@mui/icons-material/GitHub';

const PortfolioBox = ({ url, githublink, img, title, description, tech }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          padding: '24px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            '& img': {
              transform: 'rotate(340deg)',
            },
          },
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <img alt="logoimage" width="24px" height="24px" src={img}></img>
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: '800',
                color: '#1F2836',
              }}
            >
              {title}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: 'regular',
              color: '#4D545E',
            }}
          >
            {description}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: '13px',
            fontFamily: 'Inter',
            fontWeight: 'regular',
            color: '#4D545E',
            mt: '20px',
          }}
        >
          {tech}
        </Typography>
        <Stack direction="row" spacing={2} sx={{ mt: '15px' }}>
          <LaunchIcon
            onClick={() => window.open(url, '_blank')}
            style={{ cursor: 'pointer', color: '#D64C31' }}
          />

          <GitHubIcon
            onClick={() => window.open(githublink, '_blank')}
            style={{ cursor: 'pointer', color: '#D64C31' }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default PortfolioBox;
