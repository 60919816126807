import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LaunchIcon from '@mui/icons-material/Launch';

const CareerBox = ({ logo, title, jobtitle, duration, link, description }) => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          padding: '24px',
          cursor: 'pointer',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            '& img': {
              transform: 'rotate(340deg)',
            },
          },
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" spacing={1}>
            <img alt="logoimage" width="24px" height="24px" src={logo}></img>
            <Typography
              sx={{
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: '800',
                color: '#1F2836',
              }}
            >
              {title}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: 'regular',
                color: '#4D545E',
              }}
            >
              {jobtitle}
            </Typography>

            <Typography
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter',
                fontWeight: 'regular',
                color: '#4D545E',
              }}
            >
              {duration}
            </Typography>
          </Stack>

          <Typography
            sx={{
              fontSize: '16px',
              fontFamily: 'Inter',
              fontWeight: 'regular',
              color: '#4D545E',
              whiteSpace: 'pre-line',
              lineHeight: '0.9',
            }}
          >
            {description}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2} sx={{ mt: '25px' }}>
          <LaunchIcon
            onClick={() => window.open(link, '_blank')}
            style={{ cursor: 'pointer', color: '#D64C31' }}
          />
        </Stack>
      </Box>
    </>
  );
};

export default CareerBox;
