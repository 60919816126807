import React from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PortfolioBox from './PortfolioBox';
import Userlogo from '../images/userlogo.png';
import Gamelogo from '../images/onetofifty.png';
import Startuplogo from '../images/startup.png';
import Bluelogo from '../images/bwlogo.png';
import Simplelogo from '../images/logonew.png';

import { Typography } from '@mui/material';
import CareerBox from './CareerBox';

const Rightcolumn = () => {
  return (
    <>
      <Box
        sx={{
          margin: 'auto',
          pt: { xs: '32px', sm: '32px', md: '32px', lg: '0px', xl: '0px' },
        }}
      >
        <Typography
          sx={{
            textAlign: 'center',
            mt: {
              xs: '0',
              sm: '0',
              md: '0',
              lg: '54px',
              xl: '54px',
            },
            fontSize: '30px',
            fontWeight: '600',
          }}
        >
          PROJECTS
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{
            padding: {
              xs: '20px',
              sm: '20px',
              md: '54px 64px',
              lg: '54px 64px',
              xl: '54px 64px',
            },
          }}
        >
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Stack spacing={4}>
              <PortfolioBox
                url={'https://usermanagement-sjp.netlify.app/'}
                githublink={'https://github.com/sejunpark2002/newuserfront'}
                img={Userlogo}
                title={'Full Stack User Management App'}
                description={
                  'This full-stack web app offers user management capabilities, including complete CRUD. It features a report page for viewing and analyzing statistics. The backend is powered by Node.js and MongoDB'
                }
                tech={'React, Javascript,Redux, Node.js, MongoDB'}
              />

              <PortfolioBox
                url={'https://fastklik.com/'}
                githublink={'https://github.com/sejunpark2002/1to50_React'}
                img={Gamelogo}
                title={'FastKlik 1 to 50 Game'}
                description={
                  'Test and improve your reaction time. Your goal is to click numbers from 1 to 50 as quickly as possible. Each click is recorded, and your score is tracked to help you beat your personal best.'
                }
                tech={'React, Javascript'}
              />
            </Stack>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Stack spacing={4}>
              <PortfolioBox
                url={'https://startupfinder.co/'}
                githublink={'https://github.com/sejunpark2002/startuplist'}
                img={Startuplogo}
                title={'Startup Finder'}
                description={
                  'Showcasing 1,600 startups that participated in the Web Summit. Users can easily search for startups by category and name. The app also provides link to the website and LinkedIn profile.'
                }
                tech={'React, Custom React Hook'}
              />
            </Stack>
          </Grid>
        </Grid>
        <Typography
          sx={{
            textAlign: 'center',
            mt: {
              xs: '0',
              sm: '0',
              md: '0',
              lg: '54px',
              xl: '54px',
            },
            fontSize: '30px',
            fontWeight: '600',
          }}
        >
          CAREER
        </Typography>
        <Grid
          container
          spacing={4}
          sx={{
            padding: {
              xs: '20px',
              sm: '20px',
              md: '54px 64px',
              lg: '54px 64px',
              xl: '54px 64px',
            },
          }}
        >
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Stack spacing={4}>
              <CareerBox
                logo={Bluelogo}
                title={'BlueWave Labs'}
                jobtitle={'Front End Developer Intern'}
                duration={'Apr 2024 - Present'}
                description={`Developed HRM, SaaS app\n
                  • Tech stack includes React/MUI/PostgreSQL\n
                   • Worked with a team of 9 members\n
• Designed and documented various UI elements\n
• Interaction with the servers through APIs and DB\n
• Fixing bugs in new and existing projects`}
                link={'https://bluewavelabs.ca/'}
              />
            </Stack>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12}>
            <Stack spacing={4}>
              <CareerBox
                logo={Simplelogo}
                title={'Simple Webs'}
                jobtitle={'Front End Developer Freelancing'}
                duration={'Oct 2022 - Apr 2024'}
                description={`Provided custom web solutions\n
•  Built Custom Websites, WordPress applications \n
• Figma to HTML \n
• Custom code in WordPress`}
                link={'https://simplewebs.ca/'}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Rightcolumn;
