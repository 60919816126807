import React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import SejunImage from '../images/avatar.jpg';
import { Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

const Leftcolumn = () => {
  return (
    <>
      <Stack sx={{ padding: '64px 64px 40px 64px' }} spacing={2}>
        <Avatar
          alt="Sejun Park"
          src={SejunImage}
          sx={{ width: 176, height: 176 }}
        />
        <Typography
          sx={{
            fontSize: '36px',
            fontFamily: 'Inter',
            fontWeight: '800',
            color: '#1F2836',
          }}
        >
          Sejun Park
        </Typography>
        <Stack direction="row">
          <LocationOnIcon />
          <Typography
            sx={{ fontFamily: 'Inter', fontSize: '16px', color: '#1F2836' }}
          >
            Toronto, ON
          </Typography>
        </Stack>
        <Typography
          sx={{ fontFamily: 'Inter', fontSize: '18px', color: '#1F2836' }}
        >
          I am a software developer with an interest in building SaaS
          applications
        </Typography>
        <Typography
          sx={{ fontFamily: 'Inter', fontSize: '18px', color: '#1F2836' }}
        >
          Current skills include React, JavaScript,TypeScript, NodeJS, MongoDB
          and REST APIs.
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '18px',
            color: '#1F2836',
            cursor: 'pointer',
          }}
          onClick={() => (window.location.href = 'mailto:sejunparks@gmail.com')}
        >
          sejunparks@gmail.com
        </Typography>
        <Stack direction="row" spacing={2}>
          <GitHubIcon
            sx={{ cursor: 'pointer', color: '#1F2836' }}
            onClick={() =>
              (window.location.href = 'https://github.com/sejunpark2002')
            }
          />
          <LinkedInIcon
            sx={{ cursor: 'pointer', color: '#1F2836' }}
            onClick={() =>
              (window.location.href = 'https://www.linkedin.com/in/sejunparks/')
            }
          />
          <EmailIcon
            sx={{ cursor: 'pointer', color: '#1F2836' }}
            onClick={() =>
              (window.location.href = 'mailto:sejunparks@gmail.com')
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Leftcolumn;
